import Board, { moveCard, removeCard, addCard } from '@asseinfo/react-kanban'
import "@asseinfo/react-kanban/dist/styles.css";
import "./Board.css"
import useMergeState from './mergeState';
import useBoard from './store/Board';
import { TrashIcon } from "@heroicons/react/24/outline";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/24/solid";
import AddTaskModal from './AddTaskModal/AddTaskModal';
import { useState } from 'react';
import Filters from './Filters';


const BoardPage = () => {
  const [showAssetModal, setShowAssetModal] = useState(false);
  const { board, setBoard } = useBoard()
  const handleCardMove = (_card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination)
    setBoard(updatedBoard)
    console.log("lo moviste")
  }

  const getColumn = (card) => {
    const column = board.columns.filter((column) => column.cards.includes(card))
    return column[0]
  }

  const addAssetModalSetting = (selectedAssetDataId = null) => {
    //let encontrado = assets.find(x => x._id === selectedAssetDataId);
    //setUpdateAsset(encontrado);
    setShowAssetModal(!showAssetModal);
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    //setUpdatePage(!updatePage);
    console.log("OK hibrido");
  };

  const defaultFilters = {
    searchTerm: '',
    userIds: [],
    myOnly: false,
    recent: false,
  };
  const [filters, mergeFilters] = useMergeState(defaultFilters);

  return (
    <div className="board-container grid grid-cols-1 col-span-12 lg:col-span-10">

      <span>Tickets Dashboard</span>

      <Filters
        projectUsers={[]}
        defaultFilters={defaultFilters}
        filters={filters}
        mergeFilters={mergeFilters}
      />

      <Board
        allowRemoveCard
        onCardDragEnd={handleCardMove}
        renderCard={(props) => (
          <div className='kanban-card bg-gray-100'>
            <div>
              <span>
                {props.title}
              </span>
              <button className='remove-button' type='button'
                onClick={() => {
                  const updatedBoard = removeCard(board,
                    getColumn(props),
                    props
                  )
                  setBoard(updatedBoard)
                }} >

                <TrashIcon
                  className="h-6 w-6 text-blue-950"
                  aria-hidden="true"
                />
              </button>
            </div>
            <span style={{ fontSize: "15px" }}>{props.description}</span>

            <div className='flex justify-between'>
              <div className='flex'>
                <div className='inline-block'>
                  <ArrowUpIcon
                    className="h-6 w-6 text-green-600 inline" />
                </div>
                <div className='inline-block'>
                  <TagIcon
                    className="h-6 w-6 text-green-600 inline" />
                </div>
              </div>
              
              <div className='flex'>
                <div >
                  <div
                    className="h-8 w-8 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 flex align-center justify-center"
                    alt="profile"
                  >
                    <p className="pt-1">FB</p>

                  </div>
                </div>

                <div
                  className="h-8 w-8 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 flex align-center justify-center"
                  alt="profile"
                >
                  <p className="pt-1">DA</p>

                </div>
              </div>
            </div>
          </div>
        )}
        renderColumnHeader={(props) => {

          return (
            <div className='column-header'>
              <span>{props.title}</span>
              <PlusIcon
                className="h-6 w-6 text-blue-950"
                aria-hidden="true"
                onClick={addAssetModalSetting}
              />
              
            </div>
          )
        }}

      >
        {board}
      </Board>
        {showAssetModal && (
          <AddTaskModal
            addAssetModalSetting={addAssetModalSetting}
            handlePageUpdate={handlePageUpdate}
          />
        )}
    </div>
  )
}

export default BoardPage