import styled from 'styled-components';

export const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const Avatars = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 0 12px 0 2px;
`;

export const AvatarIsActiveBorder = styled.div`
  display: inline-flex;
  margin-left: -2px;
  border-radius: 50%;
  transition: transform 0.1s;
  ${props => props.isActive && `box-shadow: 0 0 0 4px #0052cc`}
  &:hover {
    transform: translateY(-5px);
  }
`;

export const ClearAll = styled.div`
  height: 32px;
  line-height: 32px;
  margin-left: 15px;
  padding-left: 12px;
  border-left: 1px solid '#dfe1e6';
  color: '#42526E';
  font-size: 14.5px;
  &:hover {
    color: '#5E6C84';
  }
`;
