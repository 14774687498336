import UsersTable from "./UsersTable";
import { getAllUsers } from '../graphClient';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';

export const UserList = (props) => {

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });
  
  const { instance } = useMsal();
  const [allUsers, setAllUsers] = useState(null);
  const [actualizados, setActualizados] = useState(false);

  var request = {
    scopes: ["User.Read.All"],
  };

  useEffect(() => {
   if (allUsers && !actualizados){ 
    execute("POST", `https://nrg-servicios.eastus.cloudapp.azure.com:8443/api/users/updateAllUsers`, allUsers).then((response) => {
          //console.log("tokenResponse = " + JSON.stringify(tokenResponse))
          console.log("USuARIIOS actualizados - OK")
          setActualizados(true);
          alert("Listado de usuarios actualizados correctamente");
  })}
  }, [allUsers]);

  // Fetching Data of All Assets
  const fetchUsersData = () => {
    execute("GET", `https://nrg-servicios.eastus.cloudapp.azure.com:8443/api/users/getAllUsers`).then((response) => {
      // si no hay usuarios guardados en la base entonces lo recupero de forma remota
      if (response && response.length < 10) {
        handleRefreshUsers();
      } else {
        setAllUsers(response);
        setActualizados(true);
      }

    })
  }

  useEffect(() => {
    fetchUsersData();
  }, [execute]);

  const fetchUsersDataFromAzure = () => {
    instance.acquireTokenSilent(request).then(tokenResponse => {
      //console.log("tokenResponse: "+JSON.stringify(tokenResponse.accessToken));
      getAllUsers(tokenResponse.accessToken).then(function (parsedData) {
        //console.log("allusers:::: " + JSON.stringify(parsedData));
        setAllUsers(parsedData.value);
      });
    }).catch(error => {
      console.log("*** NO PUDE RECOGER EL TOKEN****" + error)
    });

  };

  const handleRefreshUsers = () => {
    setActualizados(false);
    instance.acquireTokenSilent(request).then(tokenResponse => {
      getAllUsers(tokenResponse.accessToken).then(function (parsedData) {
          setAllUsers(parsedData.value);
      });
    }).catch(error => {
      console.log("*** NO PUDE RECOGER EL TOKEN****" + error)
    });
  }

  return (
    <>
      {allUsers && (
        <div className="data-area-div">
          <UsersTable data={allUsers} handleRefresh={handleRefreshUsers} />
        </div>)
      }
    </>
  );
};
