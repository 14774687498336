import { useMemo } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

function CustomTable( { data } ) {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'createdAt',
        header: 'Fecha',
        size: 150,
      },
      {
        accessorKey: 'usuario',
        header: 'Usuario',
        size: 150,
      },
      {
        accessorKey: 'operacion',
        header: 'Operacion',
        size: 150,
      },
      {
        accessorKey: 'modificaciones',
        header: 'Modificaciones',
        size: 200,
      },
      {
        accessorKey: 'usuario_email',
        header: 'Email Usuario',
        size: 250,
      }
    ],
    [],
  );

  let table_props = null;
  //false
  table_props = {
    columns,
    data, 
    
    defaultDisplayColumn: {
      enableResizing: true, //turn on some features that are usually off for all display columns
    },
    muiTableContainerProps: { sx: { maxHeight: '400px',  maxWidth: '560px'} },    
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 150, //set custom width
        muiTableHeadCellProps: {
          align: 'center', //change head cell props
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      'mrt-row-numbers': {
        enableColumnDragging: true,
        enableResizing: true,
        muiTableHeadCellProps: {
          sx: {
            fontSize: '1.2rem',
          },
        },
      },
      'mrt-row-select': {
        enableColumnActions: true,
        enableHiding: false,
        size: 40,
      },
    },
    enableColumnResizing: true,
    enableRowSelection: true,
    enableRowActions: false,
    getRowId: (originalRow) => originalRow._id
  };
  
  const table = useMaterialReactTable(table_props);
  return <MaterialReactTable table={table} />;
}

export default CustomTable;
