import React, { useState, useEffect } from "react";
import AddAsset from "../components/AddAsset";
import ConfirmDialog from "../components/ConfirmDialog";
import CustomTable from "../components/CustomTable";
import { protectedResources, groups } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { useMsal } from '@azure/msal-react';

function Inventory() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount() || instance.getAllAccounts()[0];
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateAsset, setUpdateAsset] = useState([]);
  const [deleteAsset, setDeleteAsset] = useState();
  const [assets, setAllAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [updatePage, setUpdatePage] = useState(true);
  const [totales, setTotales] = useState(0);
  const [fisicos, setFisicos] = useState(0);
  const [digitales, setDigitales] = useState(0);
  const [enReparacion, setEnReparacion] = useState(0);
  const [asignados, setAsignados] = useState(0);
  const [enableActions, setEnableActions] = useState(false);

  const [notebooksTotales, setNotebooksTotales] = useState(0);
  const [notebooksEnReparacion, setNotebooksEnReparacion] = useState(0);
  const [notebooksAsignados, setNotebooksAsignados] = useState(0);
  const [notebooksObsoletos, setNotebooksObsoletos] = useState(0);
  const [notebooksExtravados, setNotebooksExtravados] = useState(0);

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  useEffect(() => {
    fetchAssetsData();
    checkPermissions();
  }, [execute, updatePage]);

  
  const checkPermissions = () => {
    //TODO si tiene los permisos necesarios entonces puede borrarborrar el asset enableActions
    const hasRequiredGroup = activeAccount?.idTokenClaims?.groups?.includes(groups.groupAdmin)
    if (hasRequiredGroup) {
      setEnableActions(true);
    }else{
      console.log("NO TIENE los PEMISOS")
    }
  }

  useEffect(() => {
    if (assets){
      let notebooks = assets.filter(a => a.categoria.toLowerCase() == "notebook");

      let asignados = assets.filter(a => a.estado == "Asignado")?.length || -1;
      let enReparacion = assets.filter(a => a.estado == 'En Reparacion')?.length || 0;
      let fisicos = assets.filter(a => a.tipo == 'Fisico').length;
      let totales = assets.length;

      let notebooks_enReparacion = notebooks.filter(a => a.estado == "En Reparacion")?.length || 0;
      let notebooks_asignados = notebooks.filter(a => a.estado == "Asignado")?.length || 0;
      let notebooks_obsoletos = notebooks.filter(a => a.estado == "Obsoleto")?.length || 0;
      let notebooks_extraviados = notebooks.filter(a => a.estado == "Extraviado")?.length || 0;

      setNotebooksTotales(notebooks.length);
      setNotebooksEnReparacion(notebooks_enReparacion);
      setNotebooksAsignados(notebooks_asignados);
      setNotebooksObsoletos(notebooks_obsoletos);
      setNotebooksExtravados(notebooks_extraviados);

      setTotales(totales);
      setFisicos(fisicos);
      setDigitales(totales-fisicos);
      setEnReparacion(enReparacion);
      setAsignados(asignados);
    }
  }, [assets]);

  if (error) {
    return <div>Error: {error.message}</div>;
}

  // Fetching Data of All Assets
  const fetchAssetsData = () => {

    execute("GET", `https://nrg-servicios.eastus.cloudapp.azure.com:8443/api/asset/get`).then((response) => {
      setAllAssets(response);
	//console.log("----------------harian--------------------- " + JSON.stringify(response));
  })
}
  // Fetching Data of Search Assets
  const fetchSearchData = () => {
    execute("GET", `https://nrg-servicios.eastus.cloudapp.azure.com:8443/api/asset/search?searchTerm=${searchTerm}`).then((response) => {
      setAllAssets(response);
    });
  };

  // Modal for Asset ADD
  const addAssetModalSetting = (selectedAssetDataId = null) => {
    let encontrado = assets.find(x => x._id === selectedAssetDataId);
    setShowAssetModal(!showAssetModal);
    setUpdateAsset(encontrado);
  };

  // Modal for Asset UPDATE
  const updateAssetModalSetting = (selectedAssetDataId) => {
    //console.log("Clicked: edit: " + selectedAssetDataId);
    let encontrado = assets.find(x => x._id === selectedAssetDataId);
    //console.log("Clicked: edit: " + encontrado);
    setUpdateAsset(encontrado);
    setShowUpdateModal(!showUpdateModal);
  };

  // Modal for Asset Delete
  const deleteAssetModalSetting = (id) => {
    setDeleteAsset(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const changeDeleteAsset = () => {
    setShowDeleteModal(!showDeleteModal);
  }

  // Delete item
  const deleteItem = () => {
    console.log("Asset ID: ", deleteAsset);
    
    execute("POST", `https://nrg-servicios.eastus.cloudapp.azure.com:8443/api/asset/delete/${deleteAsset}`, {nombre: activeAccount["name"], email:activeAccount["username"]}).then((response) => {
      if (response && response.message === "success") {
        setUpdatePage(!updatePage);
        setShowDeleteModal(false);
      }
    });    
  };

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  // Handle Search Term
  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    fetchSearchData();
  };

  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center  mr-12">
      <div className=" flex flex-col gap-5 w-full">
        <div className="bg-white rounded p-3">
          <span className="font-semibold px-4">Resumen de Inventario</span>
          <div className=" flex flex-col md:flex-row justify-center items-center  ">
            <div className="flex flex-col gap-3 p-10   w-full  md:w-3/12 sm:border-y-2  md:border-l-2 md:border-y-0">
              <span className="font-semibold text-blue-600 text-base">
                Assets Totales
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {totales}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Totales
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 p-10   w-full  md:w-3/12 sm:border-y-2  md:border-l-2 md:border-y-0">
              <span className="font-semibold text-yellow-600 text-base">
                Por tipo
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {fisicos}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Fisicos
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {digitales}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Digitales
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 p-10  w-full  md:w-3/12  sm:border-y-2 md:border-l-2 md:border-y-0">
              <span className="font-semibold text-purple-600 text-base">
                Asignados
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {asignados}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Totales
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 p-10  w-full  md:w-3/12  border-y-2  md:border-x-2 md:border-y-0">
              <span className="font-semibold text-red-600 text-base">
                En reparacion
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {enReparacion}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    -
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="bg-white rounded p-3">
          <span className="font-semibold px-4">Resumen de Notebooks</span>
          <div className=" flex flex-col md:flex-row justify-center items-center  ">
            <div className="flex flex-col gap-3 p-10   w-full  md:w-3/12 sm:border-y-2  md:border-l-2 md:border-y-0">
              <span className="font-semibold text-blue-600 text-base">
                Notebooks Totales
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {notebooksTotales}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Totales
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 p-10   w-full  md:w-3/12 sm:border-y-2  md:border-l-2 md:border-y-0">
              <span className="font-semibold text-yellow-600 text-base">
                Estado
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {notebooksObsoletos}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Obsoleto
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {notebooksExtravados}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Extraviado
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 p-10  w-full  md:w-3/12  sm:border-y-2 md:border-l-2 md:border-y-0">
              <span className="font-semibold text-purple-600 text-base">
                Asignados
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {notebooksAsignados}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    Totales
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 p-10  w-full  md:w-3/12  border-y-2  md:border-x-2 md:border-y-0">
              <span className="font-semibold text-red-600 text-base">
                En reparacion
              </span>
              <div className="flex gap-8">
                <div className="flex flex-col">
                  <span className="font-semibold text-gray-600 text-base">
                    {notebooksEnReparacion}
                  </span>
                  <span className="font-thin text-gray-400 text-xs">
                    -
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAssetModal && (
          <AddAsset
            addAssetModalSetting={addAssetModalSetting}
            handlePageUpdate={handlePageUpdate}
          />
        )}
        {showUpdateModal && (
          <AddAsset
            addAssetModalSetting={updateAssetModalSetting}
            handlePageUpdate={handlePageUpdate}
            updateAssetData={updateAsset}
          />
        )}
        {showDeleteModal && (
          <ConfirmDialog
            confirmAction={deleteItem} // para que lo llame con el ID
            setShowDeleteModal={changeDeleteAsset}//para que lo ponga en false 
          />
        )}

        {/* Table  */}
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Assets</span>
              
            </div>

            {enableActions &&(
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addAssetModalSetting}
              >
                Crear Asset
              </button>
            </div>
            )}
          </div>
          <CustomTable 
            data={assets} 
            updateAssetModalSetting={updateAssetModalSetting} 
            deleteAssetModalSetting={deleteAssetModalSetting} 
            enableActions={enableActions}/>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
