import { useMemo } from "react";
import { Box, Button } from '@mui/material';
import { PencilIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/outline";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';

import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

function CustomTable({ data,updateAssetModalSetting,deleteAssetModalSetting, enableActions = false }) {

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  
  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: '_id',
        size: 150,
      },
      {
        accessorKey: 'numSerie',
        header: 'Num Serie',
        size: 200,
      },
    {
      //accessorFn function that combines multiple data together
      accessorFn: (row) => { return row.categoria.charAt(0).toUpperCase() + row.categoria.slice(1); },
      id: 'categoria',
      header: 'Categoria',
    },
      {
        accessorKey: 'estado',
        header: 'Estado',
        size: 200,
      },

      {
        accessorKey: 'marca',
        header: 'Marca',
        size: 200,
      },
      {
        accessorKey: 'modelo',
        header: 'Modelo',
        size: 150,
      },
      {
        accessorKey: 'asignadoA',
        header: 'Asignado A',
        size: 250,
      },
      {
        accessorKey: 'idAsignadoA',
        header: 'ID Asignado A',
        size: 250,
      },
      {
        accessorKey: 'sector',
        header: 'Sector',
        size: 150,
      },
      {
        accessorKey: 'remito',
        header: 'Remito',
        size: 150,
      },
      {
        accessorKey: 'ram',
        header: 'RAM',
        size: 150,
      },
      {
        accessorKey: 'disco',
        header: 'Disco',
        size: 150,
      },
      {
        accessorKey: 'so',
        header: 'SO',
        size: 150,
      },
      {
        accessorKey: 'fechaCompra',
        header: 'Fecha de Compra',
        size: 150,
      },
      {
        accessorKey: 'proveedor',
        header: 'Proveedor',
        size: 150,
      },
      {
        accessorKey: 'imei',
        header: 'IMEI',
        size: 150,
      },
      {
        accessorKey: 'numeroAsignado',
        header: 'Numero Asignado',
        size: 150,
      },
      {
        accessorKey: 'compania',
        header: 'Compania',
        size: 150,
      },
      {
        accessorKey: 'ubicacion',
        header: 'Ubicacion',
        size: 150,
      },
    ],
    [],
  );

  let table_props = null;
  if(!enableActions){
  //false
    table_props = {
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)

      defaultDisplayColumn: {
        enableResizing: true, //turn on some features that are usually off for all display columns
      },
      displayColumnDefOptions: {
        'mrt-row-actions': {
          size: 150, //set custom width
          muiTableHeadCellProps: {
            align: 'center', //change head cell props
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        'mrt-row-numbers': {
          enableColumnDragging: true,
          enableColumnOrdering: true, //turn on some features that are usually off
          enableResizing: true,
          muiTableHeadCellProps: {
            sx: {
              fontSize: '1.2rem',
            },
          },
        },
        'mrt-row-select': {
          enableColumnActions: true,
          enableHiding: false,
          size: 40,
        },
      },
      enableColumnResizing: true,
      enableColumnOrdering: true,
      enableRowNumbers: true,
      enableRowSelection: true,
      enableRowActions: false,
      renderRowActions: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <span
            className="cursor-pointer"
            onClick={() => updateAssetModalSetting(row.id)}
          >
            <PencilIcon className="h-6 w-6 text-blue-500 inline hover:text-blue-800" />
          </span>
          <span
            className="px-2 cursor-pointer"
            onClick={() => deleteAssetModalSetting(row.id)}
          >
            <TrashIcon className="h-6 w-6 text-blue-500 inline hover:text-blue-800" />
          </span>
        </Box>
      ),
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
          >
            Exportar todo
          </Button>
          <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Exportar pagina
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Exportar filas Seleccionadas
          </Button>
        </Box>
      ),
      getRowId: (originalRow) => originalRow._id,
      initialState: { columnVisibility: { _id: false, nombre: false, ubicacion: false, fechaCompra: false, idAsignadoA: false, proveedor: false, imei: false, numeroAsignado: false, compania: false, ram: false, disco: false, so: false, remito: false, tipo: false } }, //hide firstName column by default
    };
  }else{
    table_props = {
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)

      defaultDisplayColumn: {
        enableResizing: true, //turn on some features that are usually off for all display columns
      },
      displayColumnDefOptions: {
        'mrt-row-actions': {
          size: 150, //set custom width
          muiTableHeadCellProps: {
            align: 'center', //change head cell props
          },
          muiTableBodyCellProps: {
            align: 'center',
          },
        },
        'mrt-row-numbers': {
          enableColumnDragging: true,
          enableColumnOrdering: true, //turn on some features that are usually off
          enableResizing: true,
          muiTableHeadCellProps: {
            sx: {
              fontSize: '1.2rem',
            },
          },
        },
        'mrt-row-select': {
          enableColumnActions: true,
          enableHiding: false,
          size: 40,
        },
      },
      enableColumnResizing: true,
      enableColumnOrdering: true,
      enableRowNumbers: true,
      enableRowSelection: true,
      enableRowActions: true,
      renderRowActions: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <span
            className="cursor-pointer"
            onClick={() => updateAssetModalSetting(row.id)}
          >
            <PencilIcon className="h-6 w-6 text-blue-500 inline hover:text-blue-800" />
          </span>
          <span
            className="px-2 cursor-pointer"
            onClick={() => deleteAssetModalSetting(row.id)}
          >
            <TrashIcon className="h-6 w-6 text-blue-500 inline hover:text-blue-800" />
          </span>
        </Box>
      ),
      renderTopToolbarCustomActions: ({ table }) => (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
          >
            Exportar todo
          </Button>
          <Button
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Exportar pagina
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
          >
            Exportar filas Seleccionadas
          </Button>
        </Box>
      ),
      getRowId: (originalRow) => originalRow._id,
      initialState: { columnVisibility: { _id: false, nombre: false, ubicacion: false, fechaCompra: false, idAsignadoA: false, proveedor: false, imei: false, numeroAsignado: false, compania: false, ram: false, disco: false, so: false, remito: false, tipo: false } }, //hide firstName column by default
    };
  }
  
  const table = useMaterialReactTable(table_props);
  return <MaterialReactTable table={table} />;
}

export default CustomTable;
